import { VariantsHelper } from "../ts/variants_helper";
import { Variant } from "../ts/variant";
import { plainToClass } from 'class-transformer';
import { autobind } from "../ts/autobind";

type GrammageEntry = {
  amount: number,
  weight: string,
  meat: string,
  percentage?: string
};

type Grammage = {
  cowgenuss: GrammageEntry,
  gockelspeis: GrammageEntry,
  ringeltanz: GrammageEntry
};

type GrammageNew = {
  cg: GrammageEntry,
  gs: GrammageEntry,
  rt: GrammageEntry
};

declare global {
  interface Window {
    _tfa?: any;
    I18n: any
  }
}

declare const _tfa: any;
declare const obApi: any;

export class Recommendations {
  variants: any;

  @autobind
  async load_variants(this: Recommendations, initLoading?: boolean) {
    const loading = document.querySelector('#loading') as HTMLInputElement;

    if (!initLoading) loading.classList.add('show');

    let url = document.querySelector('#recommendation-form')!.getAttribute('data-url'),
        endpoint = `${url}`;

    fetch(endpoint).then(response => {
      response.json().then(data => {
        try {
          this.variants = plainToClass(Variant, data);
        } catch(e) {
          console.log('[update_pricing]', e)
        } finally {
          if (!initLoading) loading.classList.remove('show');
        }
      });
    });
  }

  @autobind
  async update_variants() {
    let recipes = this.current_recipes();

    if (recipes) {
      this.update_pricing();
      this.update_grammage();
    }
    this.update_plan();
    this.update_variant();
    this.update_test_package();
  }

  @autobind
  trackAddToCartEvents(): void {
    if (typeof window._tfa === 'undefined' || !_tfa || typeof obApi === 'undefined') return;

    const addToCartButtons = document.querySelectorAll('.btn-add-to-cart');

    addToCartButtons.forEach((button: Element) => {
      button.addEventListener('click', () => {
        _tfa.push({ notify: 'event', name: 'add_to_cart_petcon', id: 1368181 });
        obApi('track', 'Add to cart Petcon');
      });
    });
  }

  @autobind
  add_listeners() {
    document.addEventListener('DOMContentLoaded', function () {
      setTimeout(() => {
        window.HBRecommendations.load_variants(true);
      }, 11);
    })
    
    document.querySelectorAll('input[name^="variant"]').forEach(el => {
      el.addEventListener("change", () => {
        setTimeout(() => {
          window.HBRecommendations.update_variants();
        }, 11);
      })
    })

    this.trackAddToCartEvents();
    this.submit_form();
  }

  @autobind
  update_pricing() {
    let price = this.variants.filter(v =>
      v.option1 === this.current_recipes() && v.option2 === this.current_pension() && v.option3 === this.current_cycle())[0];

    let discountDataElement = document.getElementById("discount-data");
    let discountPercentage = parseFloat(discountDataElement.dataset.discountPercentage);
    let pricePerDayOngoing = price.metafields.filter(f => f.key === 'per_day')[0].value;
    let pricePerDayTestPackageOngoing = price.test_package.filter(f => f.key === 'per_day')[0].value;
    let pricePerDayTestPackage = price.test_package.filter(f => f.key === 'per_day')[0].value;

    let testPackageOngoingPrices: NodeListOf<Element> = document.querySelectorAll('.price-value-test-package-ongoing');
    let testPackagePrices: NodeListOf<Element> = document.querySelectorAll('.price-value-test-package');
    let ongoingPrices: NodeListOf<Element> = document.querySelectorAll('.price-value-ongoing');

    Array.prototype.forEach.call(testPackageOngoingPrices, function (testPackageOngoingPrice: HTMLElement) {
      testPackageOngoingPrice.innerText = pricePerDayTestPackageOngoing.toFixed(2).toString().replace('.', ',');
    })

    Array.prototype.forEach.call(testPackagePrices, function (testPackagePrice: HTMLElement) {
      testPackagePrice.innerText = (pricePerDayTestPackage * discountPercentage).toFixed(2).toString().replace('.', ',');
    })

    Array.prototype.forEach.call(ongoingPrices, function (ongoingPrice: HTMLElement) {
      ongoingPrice.innerText = pricePerDayOngoing.toString().replace('.', ',');
    })
  }

  @autobind
  variant_metafields(variant) : Grammage {
    const grammageEntry: GrammageEntry = {
      amount: -1,
      weight: '',
      meat: ''
    }, grammage: Grammage = {
        cowgenuss: grammageEntry,
        gockelspeis: grammageEntry,
        ringeltanz: grammageEntry
      };

    variant.metafields.filter(meta => meta.namespace === 'sachets').map(field => {
      switch (field.key) {
        case 'cowgenuss':
          grammage.cowgenuss = { 'amount': field.value, weight: '', meat: 'Rind' }; break;
        case 'gockelspeis':
          grammage.gockelspeis = { 'amount': field.value, weight: '', meat: 'Huhn' }; break;
        case 'ringeltanz':
          grammage.ringeltanz = { 'amount': field.value, weight: '', meat: 'Schwein' }; break;
        case 'cowgenuss_weight':
          grammage.cowgenuss.weight = Object.values(JSON.parse(field.value)).join(' '); break;
        case 'gockelspeis_weight':
          grammage.gockelspeis.weight = Object.values(JSON.parse(field.value)).join(' '); break;
        case 'ringeltanz_weight':
          grammage.ringeltanz.weight = Object.values(JSON.parse(field.value)).join(' '); break;
      }
    });

    return grammage;
  }

  @autobind
  variant_metafields_new(variant) : GrammageNew {
    const grammageEntry: GrammageEntry = {
      amount: -1,
      weight: '',
      meat: '',
      percentage: ''
    }, grammage: GrammageNew = {
        cg: grammageEntry,
        gs: grammageEntry,
        rt: grammageEntry
      };

    variant.metafields.filter(meta => meta.namespace === 'sachets' || meta.namespace === 'composition').map(field => {
      switch (field.key) {
        case 'cowgenuss':
          grammage.cg = { 'amount': field.value, weight: '', meat: 'Rind' }; break;
        case 'gockelspeis':
          grammage.gs = { 'amount': field.value, weight: '', meat: 'Huhn' }; break;
        case 'ringeltanz':
          grammage.rt = { 'amount': field.value, weight: '', meat: 'Schwein' }; break;
        case 'cowgenuss_weight':
          grammage.cg.weight = Object.values(JSON.parse(field.value)).join(' ').replace(' g', ''); break;
        case 'gockelspeis_weight':
          grammage.gs.weight = Object.values(JSON.parse(field.value)).join(' ').replace(' g', ''); break;
        case 'ringeltanz_weight':
          grammage.rt.weight = Object.values(JSON.parse(field.value)).join(' ').replace(' g', ''); break;
        case 'cg_percentage':
          grammage.cg.percentage = field.value; break;
        case 'gs_percentage':
          grammage.gs.percentage = field.value; break;
        case 'rt_percentage':
          grammage.rt.percentage = field.value; break;
      }
    });

    return grammage;
  }

  @autobind
  pension_html(grammage: Grammage): string {
    let html = "";

    for(let r in grammage) {
      if(grammage[r].amount > 0) {
        html += `<li class="remove-pension">${grammage[r].amount} x ${grammage[r].weight} <span class="recipe-name">${r}</span></li>`;
      }
    }

    return html;
  }

  @autobind
  update_grammage() {
    const grammage: {
        full: Grammage,
        half: Grammage
      } = {
        full: null,
        half: null
      },
      halfPension: Variant = this.variants.filter(v =>
        v.option1 === this.current_recipes() && v.option2 === 'H' && v.option3 === this.current_cycle())[0],
      fullPension: Variant = this.variants.filter(v =>
        v.option1 === this.current_recipes() && v.option2 === 'V' && v.option3 === this.current_cycle())[0];

    grammage.full = this.variant_metafields(fullPension);

    const grammageULV = document.querySelector('.pension-v .grammage') as HTMLInputElement;
    const fullHtml = this.pension_html(grammage.full);
    grammageULV.innerHTML = fullHtml;   
    
    if (typeof halfPension !== 'undefined') {
      grammage.half = this.variant_metafields(halfPension);

      const grammageULH = document.querySelector('.pension-h .grammage') as HTMLInputElement;
      if (!grammageULH) return;

      const halfHtml = this.pension_html(grammage.half);
      grammageULH.innerHTML = halfHtml;
    }
  }

  @autobind
  update_plan() {
    const pricingBar = document.querySelector('.pricing-bar');
    if (!pricingBar) return;

    const recipes = this.current_recipes();

    const allRecipes = document.querySelectorAll('[data-recipe]');
    if (!allRecipes.length) return;

    Array.prototype.forEach.call(allRecipes, function (recipeEl) {
      const isSelected = recipes.indexOf(recipeEl.dataset.recipe) !== -1;

      recipeEl.classList.toggle('d-none', !isSelected);
      recipeEl.dataset.selected = isSelected;
    })

    const selectedRecipes = Array.prototype.filter.call(allRecipes, function (recipeEl) {
      return recipeEl.dataset.selected === "true" && recipeEl.closest('.pricing-bar-recipes-indicators');
    }).map(function (recipeEl) {
      return recipeEl.dataset.title;
    });

    const pricingBarRecipeTitle = document.querySelector('.pricing-bar-recipe-title');
    pricingBarRecipeTitle.textContent = selectedRecipes.length > 0 ? selectedRecipes.length > 1 ? window.I18n.more_recipes : selectedRecipes[0] : window.I18n.no_selected_recipes;

    const more = pricingBar.querySelector('.pricing-bar-more');
    more.classList.toggle('d-none', selectedRecipes.length === 0);

    this.update_pension();
    this.update_cycle();

    const elementsToToggle: string[] = [
      '.pricing-bar-wrapper',
      '.nach-info',
      '.dein-info',
      '.dein-package',
      '.nach-package'
    ];
  
    elementsToToggle.forEach(selector => {
        const element = document.querySelector(selector) as HTMLElement;
        if (element) {
            element.classList.toggle('d-none', selectedRecipes.length === 0);
        }
    });

    const pricingBarBtn = document.querySelector('.pricing-bar-btn') as HTMLElement;

    if (selectedRecipes.length === 0) {
      pricingBarBtn.style.opacity = '0.5';
    }
    else {
      pricingBarBtn.style.opacity = '1';
    }
  }

  @autobind
  update_pension() {
    const currentPension = this.current_pension();

    const allPensions = document.querySelectorAll('[data-pension]');
    if (!allPensions.length) return;

    Array.prototype.forEach.call(allPensions, function (pensionEl) {
      pensionEl.textContent = currentPension === 'H' ? window.I18n.pension.half : window.I18n.pension.full;
    })
  }

  @autobind
  update_cycle() {
    const currentCycle = this.current_cycle();

    const allCycles = document.querySelectorAll('[data-cycle]');
    if (!allCycles.length) return;

    Array.prototype.forEach.call(allCycles, function (cycleEl) {
      cycleEl.textContent = currentCycle;
    })
  }

  @autobind
  update_variant() {
    const variant = this.current_variant()

    const metafields = this.variant_metafields_new(variant);

    const allRecipes = document.querySelectorAll('.after-recipe[data-recipe]');
    if (!allRecipes.length) return;

    Array.prototype.forEach.call(allRecipes, function (recipeEl) {
      if (recipeEl.dataset.selected) {
        const recipe = metafields[recipeEl.dataset.recipe.toLowerCase()];

        const dataQuantity = recipeEl.querySelector('[data-quantity]');
        const dataGrammage = recipeEl.querySelector('[data-grammage]');

        if(dataQuantity) dataQuantity.textContent = recipe.amount;
        if(dataGrammage) dataGrammage.textContent = recipe.weight;
      }
    });
  }

  @autobind
  update_test_package() {
    const variant = this.current_test_package()

    const metafields = this.variant_metafields_new(variant);

    const allRecipes = document.querySelectorAll('.test-recipe[data-recipe]');
    if (!allRecipes.length) return;

    Array.prototype.forEach.call(allRecipes, function (recipeEl) {
      if (recipeEl.dataset.selected) {
        const recipe = metafields[recipeEl.dataset.recipe.toLowerCase()];

        const dataQuantity = recipeEl.querySelector('[data-quantity]');
        const dataGrammage = recipeEl.querySelector('[data-grammage]');
        const dataPercentage = recipeEl.querySelector('[data-percentage]');

        if(dataQuantity) dataQuantity.textContent = recipe.amount;
        if(dataGrammage) dataGrammage.textContent = recipe.weight;
        if(dataPercentage) dataPercentage.textContent = recipe.percentage;
      }
    });
  }

  @autobind
  current_variant() {
    const variant = this.variants.find(v => 
        v.option1 === this.current_recipes() &&
        v.option2 === this.current_pension() &&
        v.option3 === this.current_cycle()
      );

    return variant;
  }

  @autobind
  current_test_package() {
    const testPackageEl: HTMLElement = document.querySelector('[data-test-package]');
    if (!testPackageEl) return null;

    const variant = this.variants.find(v => 
        v.option1 === this.current_recipes() &&
        v.option2 === this.current_pension() &&
        v.option3 === testPackageEl?.dataset.testPackage
      );

    return variant;
  }

  @autobind
  current_recipes() {
    return Array.from(document.querySelectorAll('input[name="variant[recipe][]"]:checked'))
      .map(e => (e as HTMLInputElement).value).join('');
  }

  @autobind
  current_cycle() {
    return document.querySelector('.cycle.item.active').querySelector('input').value
  }

  @autobind
  current_pension() {
    return document.querySelector('.pension.item.active').querySelector('input').value
  }

  @autobind
  submit_form() {
    const submitBtn: HTMLButtonElement[] = Array.from(
      document.querySelectorAll('.recommendation-submit')
    ) as HTMLButtonElement[];
    if (!submitBtn.length) return;

    const form: HTMLFormElement = document.querySelector('#recommendation-form');
    if (!form) return;

    Array.prototype.forEach.call(submitBtn, (button: HTMLButtonElement) => 
    button.addEventListener('click', function () {
      form.submit();
    }))
  }
}