var validate = require("validate.js");

const IS_PUPPY_VALIDATION = PET.dog_stage === 'puppy' && STEP === 'weight';

window.addEventListener('DOMContentLoaded', function () {
  validateForm();
});

validate.validators.customWeight = function (value, options) {
  var floatValue = parseFloat(value);

  var patternPuppy = /^(?:0\.[5-9][0-9]?|[1-9]|[1-6][0-9]|70)(?:\.[0-9]{1,2})?$/;
  var pattern = /^([2-9]|[1-6][0-9]|70)(?:\.[0-9]{1,2})?$/;
  
  if (isNaN(floatValue)) {
    return options.notValid
  } else if ((floatValue < 0.5 || floatValue > 70) && IS_PUPPY_VALIDATION) {
    return floatValue < 0.5 ? options.notGt : options.notLt;
  } else if ((floatValue < 2|| floatValue > 70) && !IS_PUPPY_VALIDATION) {
    return floatValue < 2 ? options.notGt : options.notLt;
  } else if (!patternPuppy.test(floatValue) && IS_PUPPY_VALIDATION || !pattern.test(floatValue) && !IS_PUPPY_VALIDATION) {
    return options.notValid
  } else {
    // if the validator passes return null or undefined.
    return null;
  }
};

validate.validators.presence.message = "darf nicht leer sein.";

var validateForm = function () {
  // used to actively show validation status after first submission
  var isSubmittedOnce = false;
  // Initialize constraints 
  var constraints = {};
  // Hook up the form so we can prevent it from being posted
  var body = document.querySelector('body');
  var form = document.querySelector("form");

  if (!form) {
    return;
  }

  form.addEventListener("submit", function (e) {
    e.preventDefault();
    handleFormSubmit(form);
  });

  document.addEventListener('keyup', function (e) {
    if (e.key === 'Enter') handleFormSubmit(form);
  });

  // Hook up the inputs to validate on the fly
  var inputs = form.querySelectorAll(".required");
  for (var i = 0; i < inputs.length; ++i) {
    addConstraint(inputs.item(i));
    if (/radio/.test(inputs.item(i).getAttribute('type'))) {
      eventListener = 'click';
      input = inputs.item(i).parentNode;
    } else if (inputs.item(i).tagName === 'SELECT') {
      eventListener = 'change';
      input = inputs.item(i);
    } else {
      eventListener = 'input';
      input = inputs.item(i);
    }

    input.addEventListener(eventListener, function (ev) {
      var errors = validate(form, constraints) || {};
      showErrorsForInput(this, errors[this.name]);
      handleValidForm(form, Object.keys(errors).length);
    });
  }

  // Validate fomr on load - in case the form is already prefilled
  (function validateOnLoad(form) {
    var errors = validate(form, constraints) || {};
    handleValidForm(form, Object.keys(errors).length);
  })(form);

  function handleFormSubmit(form, input) {
    isSubmittedOnce = true;
    // validate the form against the constraints
    var errors = validate(form, constraints);
    // then we update the form to reflect the results
    showErrors(form, errors || {});
    if (!errors) {
      showSuccess(form);
    }
  }

  // Add a new constrains object property for each form field
  function addConstraint(field) {
    var validateObj = {};
    var name = field.getAttribute('name');

    var message = field.dataset.validateMessage || '';
    if (IS_PUPPY_VALIDATION) message = message.replace('2', '0.5');

    validateObj['presence'] = {
      'message': message ? '^' + message : true,
      'allowEmpty': false
    }  

    if (field.dataset.validate) {
      var validateType = field.dataset.validate;

      if (validateType === 'numericality') {
        var min = field.dataset.validateMin || 0;
        var max = field.dataset.validateMax || 1000;
        var onlyInteger = field.dataset.onlyInteger === 'true' || false;

        var messageGreaterThan = field.dataset.validateMessageGreaterThan || '';
        var messageLessThan = field.dataset.validateMessageLessThan || '';

        var validateAdditional = {
          onlyInteger: onlyInteger,
          greaterThanOrEqualTo: parseInt(min),
          lessThanOrEqualTo: parseInt(max),
          notGreaterThanOrEqualTo: '^' + messageGreaterThan,
          notLessThanOrEqualTo: '^' + messageLessThan,
        }

        validateObj[validateType] = validateAdditional;
      } else if (validateType === 'customWeight') {
        var messageGreaterThan = field.dataset.validateMessageGreaterThan || '';
        if (IS_PUPPY_VALIDATION) messageGreaterThan = messageGreaterThan.replace('2', '0.5') || '';
        var messageLessThan = field.dataset.validateMessageLessThan || '';
        var messageValidValues = field.dataset.validateMessage || '';
        if (IS_PUPPY_VALIDATION) messageValidValues = field.dataset.validateMessage.replace('2', '0.5') || '';

        validateObj['customWeight'] = {
          notValid: '^' + messageValidValues,
          notGt: '^' + messageGreaterThan,
          notLt: '^' + messageLessThan
        }
      } else if (validateType === 'email') {
        var messageEmailInvalid = field.dataset.validateMessageEmail || '';
        validateObj['email'] = messageEmailInvalid ? { message: '^' + messageEmailInvalid } : true;
      }
    }

    constraints[name] = validateObj;
  }

  // Updates the inputs with the validation errors
  function showErrors(form, errors) {
    var elements = form.querySelectorAll("input[name]:not([type='hidden']), select[name]");
    // We loop through all the inputs and show the errors for that input
    Array.prototype.forEach.call(elements, function (input) {
      // Since the errors can be null if no errors were found we need to handle
      // that
      showErrorsForInput(input, errors && errors[input.name]);
    });
  }

  // Shows the errors for a specific input
  function showErrorsForInput(input, errors) {
    if (!isSubmittedOnce) return;
    // This is the root of the input
    var formGroup = closestParent(input.parentNode, "form-group")
      // Find where the error messages will be insert into
      , messages = formGroup.querySelector(".messages");
    // First we remove any old messages and resets the classes
    resetFormGroup(formGroup);
    // If we have errors
    if (errors) {
      // we first mark the group has having errors
      formGroup.classList.add("has-error");
      // then we append all the errors
      Array.prototype.forEach.call(errors, function (error) {
        addError(formGroup, error);
      });
    } else {
      // otherwise we simply mark it as success
      formGroup.classList.add("has-success");
    }
  }

  function resetFormValidation(form) {
    body.classList.remove('valid');
    form.classList.remove('valid');
  }

  function handleValidForm(form, errors) {
    resetFormValidation(form);
    if (!errors) {
      body.classList.add('valid');
      form.classList.add('valid');
    }
  }

  // Recusively finds the closest parent that has the specified class
  function closestParent(child, className) {
    if (!child || child == document) {
      return null;
    }
    if (child.classList.contains(className)) {
      return child;
    } else {
      return closestParent(child.parentNode, className);
    }
  }

  function resetFormGroup(formGroup) {
    // Remove the success and error classes
    formGroup.classList.remove("has-error");
    formGroup.classList.remove("has-success");
    var helperBlocks = formGroup.querySelectorAll(".help-block.error");
    // and remove any old messages
    Array.prototype.forEach.call(helperBlocks, function (el) {
      el.parentNode.removeChild(el);
    });
  }

  // Adds the specified error with the following markup
  // <p class="help-block error">[message]</p>
  function addError(messages, error) {
    var block = document.createElement("p");
    block.classList.add("help-block");
    block.classList.add("error");
    block.innerText = error;
    messages.appendChild(block);
  }

  function showSuccess(form) {
    form.submit();
  }
};
