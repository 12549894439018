/**
 * Helper class representing a product variant
 */
export class Variant {
  id: number;
  product_id: number;
  title: string;
  price: number;
  sku: string;
  position: number;
  inventory_policy: 'deny';
  compare_at_price: string | null;
  fulfillment_service: 'manual';
  inventory_management: string | null;
  option1: string;
  option2: string;
  option3: string;
  created_at: Date;
  updated_at: Date;
  taxable: boolean;
  barcode: string | null;
  grams: number;
  image_id: number;
  weight: number;
  weight_unit: 'kg';
  inventory_item_id: number;
  inventory_quantity: number;
  old_inventory_quantity: number;
  requires_shipping: boolean;
  admin_graphql_api_id: string;
  metafields: [Metafield];
  selling_plan_id: number;
}

export class Metafield {
  id: number;
  namespace: string;
  key: string;
  value:36;
  value_type: any;
  description: string;
  owner_id: number;
  created_at: Date;
  updated_at: Date;
  owner_resource: "variant";
  type: string;
  admin_graphql_api_id: string;
}