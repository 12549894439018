// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import { Recommendations } from "./recommendations"
// import Turbolinks from "turbolinks"
// import '../js/bootstrap_js_files.js'
import '../js/dependencies/dependencies'
import '../js/pet-profile'
import '../js/custom'

Rails.start()
// Turbolinks.start()
// ActiveStorage.start()

declare global {
  interface Window { HBRecommendations: Recommendations; }
}

window.HBRecommendations = window.HBRecommendations || new Recommendations();
